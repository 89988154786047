@media (max-width: 1457px) {
  .rowgap-vbox {
    padding: 40px 15px;
  }
  .padding_left_right {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 1199px) {
  /* .signin-card {
    margin-top: 100px;
  } */
  .rowgap-vbox {
    padding: 40px 10px;
  }
}
@media (min-width: 992px) {
  .layout-dashboard .ant-layout-footer {
    margin: 0 0 20px 20px;
    margin-left: 270px;
    padding: 0px 15px;
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    margin: 0px 0 0 20px;
    padding: 21px 0.6px 33px 0px;
    height: calc(100vh - 20px);
  }
  /* .signin-card {
    margin-top: 70px;
  } */

  .layout-dashboard .ant-layout .main-setting {
    width: auto;
    flex-shrink: 1;
    margin-left: 270px;
    margin-top: 90px;
  }
  .layout-dashboard .ant-layout .footer-setting {
    margin-left: 270px;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
}
@media (max-width: 992px) {
  .layout-dashboard .ant-layout-sider.sider-primary {
    margin: 0px 0 0 20px;
    padding: 33px 0.6px 33px 0px;
    height: calc(100vh - 20px);
  }
}
@media (min-width: 768px) {
  .layout-dashboard .ant-layout-header {
    padding: 15px 20px 15px 20px;
  }
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
}

@media (max-width: 768px) {
  .layout-dashboard.layout-dashboard-rtl .header-control {
    justify-content: flex-end;
  }
  .rowgap-vbox {
    padding: 40px 10px;
  }
  .heading {
    font-size: 16px !important;
  }
  .padding-left-6 {
    padding-left: 5px !important;
  }
  .btn-style-view {
    height: 40px;
    padding: 10px 15px;
    font-size: 15px;
  }
  .space-between {
    margin: 5px;
    padding: 10px 10px;
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    top: 0px;
  }
}

@media (max-width: 768px) {
  .banner_section {
    display: block;
    align-items: center;
  }
  .upload_image_style {
    margin-top: 20px;
    margin-left: 0px;
  }
  .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item a,
  .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu a {
    font-size: 13px;
  }
}
@media (max-width: 568px) {
  .project-ant {
    display: block !important;
  }
  .card-style-sub {
    display: block;
  }
  .card_left {
    display: block;
  }
  .card_right {
    display: block;
  }
  .content_between {
    display: block;
  }
}
